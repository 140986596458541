document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".woof_container_inner h4").forEach(function (h) {
        if (h.querySelector("a.woof_front_toggle")) {
            h.style.cursor = "pointer";
            h.addEventListener("click", function (e) {
                h.querySelector("a.woof_front_toggle").click();
                return false;
            });
        }
    });
    if (document.getElementById("filter")) {
        document.querySelector(".woof_submit_search_form").innerText =
            "Применить";
        document.querySelector(".woof_reset_search_form").innerText =
            "Очистить";
    }

    // Кнопка меню
    // let menus = document.querySelectorAll(".hamburger-menu");
    // for (let i = 0; i < menus.length; i++) {
    //     menus[i].addEventListener("click", function () {
    //         menus[i].classList.toggle("open");
    //     });
    // }

    //  Мобильное меню
    let menuBtn = document.querySelectorAll(".hamburger-menu");
    let mobileMenu = document.querySelector(".mobile-nav__wrap");
    const menus = document.querySelectorAll(".mobile-nav__wrap a");

    for (let i = 0; i < menuBtn.length; i++) {
        menuBtn[i].addEventListener("click", function () {
            menuBtn[i].classList.toggle("open");
            mobileMenu.classList.toggle("open");
            document.querySelector("body").classList.toggle("body-block");
        });
    }
    for (let i = 0; i < menus.length; i++) {
        menus[i].addEventListener("click", () => {
            document.querySelector(".hamburger-menu").classList.remove("open");
            document
                .querySelector(".mobile-nav__wrap")
                .classList.remove("open");
            document.querySelector("body").classList.remove("body-block");
        });
    }

    // Слайдер баннеров на главной
    const swiperBanner = new Swiper(".banner", {
        slidesPerView: 1, // Слайдов на экран
        spaceBetween: 0, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: true, // Бесконечная прокрутка
        lazy: true, // Ленивая загрузка
        speed: 300, // Скорость анимации
        parallax: true, // Параллакс эффект
        autoplay: {
            // Автоплей
            delay: 5000, // Время автоплея
            disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
            pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        },
        navigation: {
            nextEl: ".banner-arrow__next",
            prevEl: ".banner-arrow__prev",
        },
        pagination: {
            el: ".banner-pagination",
            clickable: true,
        },
    });

    // Слайдер новости
    var swiperNewsThumbs = new Swiper(".single-slider__thumbs", {
        loop: false,
        spaceBetween: 20,
        slidesPerView: 4,
        watchSlidesProgress: true,
    });

    const swiperNews = new Swiper(".single-slider", {
        spaceBetween: 10, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: false, // Бесконечная прокрутка
        // autoplay: {
        //     // Автоплей
        //     delay: 8000, // Время автоплея
        //     disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
        //     pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        // },
        navigation: {
            nextEl: ".single-slider__arrow_next",
            prevEl: ".single-slider__arrow_prev",
        },
        thumbs: {
            swiper: swiperNewsThumbs,
        },
        pagination: {
            el: ".single-slider__pagination",
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 15,
                centeredSlides: true,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
        },
    });

    // Удаляем ссылку обёртку у тамбов слайдера товара
    let productThumbs = $(
        ".product .single-slider__thumbs .single-slider__item a"
    );

    productThumbs.each(function () {
        $(this).replaceWith($(this.innerHTML));
    });

    // Проверяем кол-во не дублированных слайдов, если 1 или < удаляем тамбы, стрелки и дубликаты у главного слайдера
    // let productThumbsItems = $(".single-slider__thumbs .swiper-wrapper");
    // let productThumbsNoDuplicate = $(
    //     ".single-slider__thumbs .single-slider__item:not(.swiper-slide-duplicate)"
    // );
    // let productSliderDuplicate = $(
    //     ".single-slider .single-slider__item.swiper-slide-duplicate"
    // );
    // let productThumbsWrap = $(".single-slider__thumbs");
    // let productSliderArrowPrev = $(".single-slider__arrow_prev");
    // let productSliderArrowNext = $(".single-slider__arrow_next");
    // if (productThumbsNoDuplicate.length < 4) {
    //     // productSliderDuplicate.remove();
    // }
    // console.log(productThumbsItems.length);
    // if (productThumbsItems.length == 1) {
    //     productThumbsWrap.remove();
    //     productSliderArrowPrev.remove();
    //     productSliderArrowNext.remove();
    // }

    //	Аккордеон
    $(".product-accordeon dd")
        .hide()
        .prev()
        .click(function () {
            $(this)
                .parents(".product-accordeon")
                .find("dd")
                .not(this)
                .slideUp()
                .prev()
                .removeClass("active");
            $(this)
                .next()
                .not(":visible")
                .slideDown("200")
                .prev()
                .addClass("active");
        });

    // Удаление аккордеона если в нем нет дочерних элементов
    let productAccordeon = $(".product-accordeon");
    let productAccordeonLength = productAccordeon.children().length;
    if (productAccordeonLength == 0) {
        productAccordeon.remove();
    }

    $(window).on("resize load", function () {
        if ($(window).width() < 640) {
            // Перенос заголовка наверх для статьи
            var singleContainer = $(".single-slider__container"),
                singleTitle = $(".single-slider__content_title");
            singleTitle.prependTo(singleContainer);

            // Список в слайдер для "Клиенты"
            // var clientsWrap = $(".clients"),
            //     clientsItem = $(".clients-item");
            // clientsWrap.addClass("swiper");
            // clientsItem.addClass("swiper-slide");
            // clientsItem.wrapAll('<div class="swiper-wrapper" />');
            // const swiperClients = new Swiper(".clients", {
            //     slidesPerView: 1,
            //     spaceBetween: 10, // Отступы между слайдами
            //     centeredSlides: true, // Центровка слайдов
            //     loop: true, // Бесконечная прокрутка
            //     navigation: {
            //         nextEl: ".clients-arrow_next",
            //         prevEl: ".clients-arrow_prev",
            //     },
            //     pagination: {
            //         el: ".clients-pagination",
            //         clickable: true,
            //     },
            // });

            // // Список в слайдер для "Производители"
            // var vendorsWrap = $(".vendors"),
            //     vendorsItem = $(".vendors-item");
            // vendorsWrap.addClass("swiper");
            // vendorsItem.addClass("swiper-slide");
            // vendorsItem.wrapAll('<div class="swiper-wrapper" />');
            // const swiperVendors = new Swiper(".vendors", {
            //     slidesPerView: 1,
            //     spaceBetween: 10, // Отступы между слайдами
            //     centeredSlides: true, // Центровка слайдов
            //     loop: true, // Бесконечная прокрутка
            //     navigation: {
            //         nextEl: ".vendors-arrow_next",
            //         prevEl: ".vendors-arrow_prev",
            //     },
            //     pagination: {
            //         el: ".vendors-pagination",
            //         clickable: true,
            //     },
            // });

            // Добавляем классы active фильтрам
            // $(".catalog-filter__title").addClass("active");
            // $(".catalog-filter__list").addClass("active");
        } else {
            // Фильтры
            // const filterTitle = document.querySelectorAll(
            //     ".catalog-filter__title"
            // );
            // const filterContent = document.querySelectorAll(
            //     ".catalog-filter__list"
            // );
            // for (let i = 0; i < filterTitle.length; i++) {
            //     filterTitle[i].addEventListener("click", () => {
            //         if (filterTitle[i].classList.contains("active")) {
            //             filterContent[i].classList.remove("active");
            //             filterTitle[i].classList.remove("active");
            //         } else {
            //             for (let j = 0; j < filterContent.length; j++) {
            //                 filterContent[j].classList.remove("active");
            //             }
            //             for (let jj = 0; jj < filterTitle.length; jj++) {
            //                 filterTitle[jj].classList.remove("active");
            //             }
            //             filterContent[i].classList.add("active");
            //             filterTitle[i].classList.add("active");
            //         }
            //     });
            // }
        }
    });
});
